<template>
  <div class="container py-2 font-bold flex-col align-center uppercase">
    <div class="font-bold leading-tight text-jm_white px-20 text-center uppercase">
      {{ $t("age_check.how_many_people") }}
    </div>
    <div class="flex justify-center w-full">
      <div class="person-picker bg-jm_green rounded-[10px]">
        <input
          type="number"
          class="people-input font-extrabold"
          v-model="selectedPerson"
          @input="updatePeopleCount"
        />
      </div>
    </div>
  </div>
</template>


<script setup lang="ts">
import { ref } from "vue";
import { useGlobalStore } from "../stores/global";

const globalStore = useGlobalStore();
const selectedPerson = ref<number | null>(1);

const updatePeopleCount = () => {
  const personCount = parseInt(selectedPerson.value as any);
  if (!isNaN(personCount) && personCount > 0) {
    globalStore.setSelectedPerson(personCount);
  } else {
    selectedPerson.value = null;
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/main.sass";

.container {
  z-index: 1;
  width: fit-content;
}

.person-picker {
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  padding: 0.75rem;
  width: fit-content;
}

.people-input {
  padding-left: 20px;
  padding: 0.5rem;
  border: 1px solid $jm_white;
  border-radius: 10px;
  font-size: 1rem;
  width: 100px;
  text-align: center;
  background-color: $jm_green;
  color: $jm_white;
  max-width: 140px;
  color: $jm_orange;
}
</style>
