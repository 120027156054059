<template>
  <DefaultLayout background-image="green">
    <div class="flex flex-col items-center h-full">
      <div class="flex-1 flex flex-col justify-center">
        <img
          :src="logo"
          alt="logo"
          class="w-[150px] mb-6 slide mx-auto"
        />
        <div class="text-lg leading-tight max-w-md text-jm_white px-3 [text-shadow:_0_0px_10px_rgba(0_0_0_/_40%)]">
          {{ $t("age_check.message") }}
        </div>
        <br />
        <div class="text-lg font-bold uppercase leading-tight max-w-md text-jm_white px-3 [text-shadow:_0_0px_10px_rgba(0_0_0_/_40%)]">
          {{ $t("age_check.enter_birthday") }}
        </div>
        <div class="">
          <AgeSelector />
        </div>
        <div></div>
      </div>
      <div class="relative flex flex-col justify-center items-center">
        <div>
          <img
            :src="drinkReponsible"
            alt="drink responsible"
            class="w-[160px] mb-6"
          />
        </div>

        <div
          class="text-sm uppercase cursor-pointer opacity-50 text-jm_white mb-4 text-center"
          @click="globalStore.changeStep('termsAndConditions')"
        >
          {{ $t("age_check.legal_conditions") }}
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from "../layouts/DefaultLayout.vue";
import AgeSelector from "@/components/AgeSelector.vue";
import logo from "@/assets/images/misc/logo.png";
import drinkReponsible from "@/assets/images/misc/drink-responsible.svg";
import { useGlobalStore } from "../stores/global";

const globalStore = useGlobalStore();
</script>

<style lang="scss" scoped>
@import "@/assets/sass/main.sass";
</style>
