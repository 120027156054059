import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container py-2 font-bold flex-col align-center uppercase" }
const _hoisted_2 = { class: "font-bold leading-tight text-jm_white px-20 text-center uppercase" }
const _hoisted_3 = { class: "flex justify-center w-full" }
const _hoisted_4 = { class: "person-picker bg-jm_green rounded-[10px]" }

import { ref } from "vue";
import { useGlobalStore } from "../stores/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'PeopleSelector',
  setup(__props) {

const globalStore = useGlobalStore();
const selectedPerson = ref<number | null>(1);

const updatePeopleCount = () => {
  const personCount = parseInt(selectedPerson.value as any);
  if (!isNaN(personCount) && personCount > 0) {
    globalStore.setSelectedPerson(personCount);
  } else {
    selectedPerson.value = null;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("age_check.how_many_people")), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          type: "number",
          class: "people-input font-extrabold",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedPerson).value = $event)),
          onInput: updatePeopleCount
        }, null, 544), [
          [_vModelText, selectedPerson.value]
        ])
      ])
    ])
  ]))
}
}

})