import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-jm_white overflow-auto text-sm p-6" }

import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { useGlobalStore } from "../stores/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'termsAndConditions',
  setup(__props) {

const globalStore = useGlobalStore();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DefaultLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(globalStore).changeStep('ageCheck'))),
          class: "text-lg font-bold border-2 inline-block py-2 px-6 rounded-lg border-jm_orange text-jm_orange mb-6"
        }, "← Back"),
        _cache[1] || (_cache[1] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, "Terms of Use")
        ], -1)),
        _cache[2] || (_cache[2] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, "for the Online Game „Buzzer Game”")
        ], -1)),
        _cache[3] || (_cache[3] = _createElementVNode("p", null, " ", -1)),
        _cache[4] || (_cache[4] = _createElementVNode("p", null, " These Terms of Use (the “Terms”) govern your participation in and use of the online game “Buzzer Game” (the “Game”). These Terms are entered into between you and Mast-Jägermeister SE, Jägermeisterstraße 7-15, 38296 Wolfenbüttel, Germany (“MJSE”). You and MJSE are each referred to as a “Party” and jointly the “Parties”. ", -1)),
        _cache[5] || (_cache[5] = _createElementVNode("p", null, " ", -1)),
        _cache[6] || (_cache[6] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, "Background")
        ], -1)),
        _cache[7] || (_cache[7] = _createElementVNode("p", null, " MJSE has developed the Game which is run on a website (the “Game Portal”) operated by MJSE. Participants access the Game via their smartphone browser by scanning a QR code printed on promotion material (e.g. table tents). ", -1)),
        _cache[8] || (_cache[8] = _createElementVNode("p", null, " ", -1)),
        _cache[9] || (_cache[9] = _createElementVNode("ol", null, [
          _createElementVNode("li", null, [
            _createElementVNode("strong", null, "Obligations of MJSE")
          ]),
          _createElementVNode("li", null, "MJSE hereby gives you permission to use the Game in accordance with these Terms."),
          _createElementVNode("li", null, "MJSE operates and maintains the Game Portal as an IT service provider to enable you to offer the Game to your customers."),
          _createElementVNode("li", null, " MJSE provides you with promotional (print) assets equipped with a QR code that leads participants to the Game Portal. The promotional templates can be downloaded on the GTM Platform by your brand manager and will be handed out to you as printed assets that can be placed in your outlet. "),
          _createElementVNode("li", null, " MJSE provides universal legal notices to be accessed by the participants through the Game Portal (data privacy notice; terms and conditions for participants). See Annexes for the template legal notices. ")
        ], -1)),
        _cache[10] || (_cache[10] = _createElementVNode("p", null, " ", -1)),
        _cache[11] || (_cache[11] = _createElementVNode("ol", { start: "2" }, [
          _createElementVNode("li", null, [
            _createElementVNode("strong", null, "Obligations of you")
          ]),
          _createElementVNode("li", null, "You will offer the Game in your own responsibility."),
          _createElementVNode("li", null, [
            _createTextVNode(" You warrant that you will only offer the Game if "),
            _createElementVNode("ol", null, [
              _createElementVNode("li", null, "i) it is generally permitted under your local laws, and"),
              _createElementVNode("li", null, "ii) the legal notices provided in the Annexes fulfil all local requirements.")
            ])
          ])
        ], -1)),
        _cache[12] || (_cache[12] = _createElementVNode("p", null, "If necessary, you will in your responsibility apply for any government license to offer the Game.", -1)),
        _cache[13] || (_cache[13] = _createElementVNode("ol", null, [
          _createElementVNode("li", null, " You will inform MJSE of any particularities or requirements under your local laws that need to be considered in relation to the legal notices. MJSE may decide in its sole discretion whether it will implement changes to the legal notices accordingly. "),
          _createElementVNode("li", null, " You determine the prize that can be won by the participants. Or they use it as a bet who pays the next shot of ice cold Jägermeister. However, you are only allowed to award “Jägermeister” products as prizes for winning participants. "),
          _createElementVNode("li", null, " If you award “Jägermeister” beverages to winning participants, you ensure that the respective participant may consume such alcoholic beverages under the applicable laws. "),
          _createElementVNode("li", null, "If you permit any other person to offer the Game, you shall procure that they adhere to these Terms.")
        ], -1)),
        _cache[14] || (_cache[14] = _createElementVNode("p", null, " ", -1)),
        _cache[15] || (_cache[15] = _createElementVNode("ol", { start: "3" }, [
          _createElementVNode("li", null, [
            _createElementVNode("strong", null, " Intellectual Property")
          ])
        ], -1)),
        _cache[16] || (_cache[16] = _createElementVNode("p", null, "You may use the print templates that contain copyrighted material and MJSE trademarks only for the promotion of the Game.", -1)),
        _cache[17] || (_cache[17] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, " ")
        ], -1)),
        _cache[18] || (_cache[18] = _createElementVNode("ol", { start: "4" }, [
          _createElementVNode("li", null, [
            _createElementVNode("strong", null, " Data Protection")
          ]),
          _createElementVNode("li", null, " The Parties have concluded that they are joint controllers in relation to the processing of personal data through the Game Portal and that you act as a separate and independent controller with regard to any other data processing activities in connection with the performance, implementation or execution of the Game (e.g. age verification of participants as required under applicable laws). "),
          _createElementVNode("li", null, " MJSE collects and processes the personal data of participants as set out in the privacy notice in Annex 1. You are not involved in nor responsible for any of these data processing activities. You shall not collect any further personal data in connection with the performance, implementation, or execution of the Game unless strictly necessary under the applicable laws. "),
          _createElementVNode("li", null, "Each Party ensures the compliance with the applicable data protection laws."),
          _createElementVNode("li", null, " MJSE informs the participants about the particularities of the processing of personal data through the Game Portal via the privacy notice in Annex 1 (as updated from time to time). You shall inform the participants regarding any other processing activities in connection with the performance of the Game insofar as you act as a separate and independent controller. "),
          _createElementVNode("li", null, " MJSE fulfils the data subject rights vis-à-vis the participants insofar as the Parties act as joint controllers. MJSE will answer to potential data access requests. If participants direct respective requests to you, you may forward the request to MJSE for further handling. The Parties will agree on which Party will lead the correspondence with the data subject on a case-by-case basis. You will fulfil the data subject rights vis-à-vis the participants insofar as you act as separate and independent controller. "),
          _createElementVNode("li", null, "The Parties will support each other in satisfying its potential notification obligations toward data subjects and data protection authorities.")
        ], -1)),
        _cache[19] || (_cache[19] = _createElementVNode("p", null, " ", -1)),
        _cache[20] || (_cache[20] = _createElementVNode("ol", { start: "5" }, [
          _createElementVNode("li", null, [
            _createElementVNode("strong", null, " Exclusion of warranties")
          ]),
          _createElementVNode("li", null, " MJSE provides the Game and Game Portal “as is” and does not give any express or implied warranties. MJSE does not give any warranty of compliance with applicable laws, non-infringement, or any express or implied warranty of merchantability, fitness for a particular purpose, course of dealing and usage of trade, without limiting the foregoing, MJSE does not warrant that the Game Portal will be fail-safe, fault-tolerant, uninterrupted, error free, free of harmful components, or that any content will be secure or otherwise lost or damages. "),
          _createElementVNode("li", null, "Paragraph (a) does not apply to the extent prohibited by applicable law.")
        ], -1)),
        _cache[21] || (_cache[21] = _createElementVNode("p", null, " ", -1)),
        _cache[22] || (_cache[22] = _createElementVNode("ol", { start: "6" }, [
          _createElementVNode("li", null, [
            _createElementVNode("strong", null, " Liability")
          ]),
          _createElementVNode("li", null, " Subject to the following paragraphs of this Clause 6, the Parties shall not be liable to each other for damage or losses arising out of a breach of these Terms. "),
          _createElementVNode("li", null, [
            _createTextVNode(" You shall indemnify MJSE for losses arising out of a violation of "),
            _createElementVNode("ol", null, [
              _createElementVNode("li", null, "i) Clause 3 (b), and"),
              _createElementVNode("li", null, "ii) local laws that you were obliged to inform MJSE about under Clause 3 (c).")
            ])
          ]),
          _createElementVNode("li", null, [
            _createTextVNode(" The exclusion of liability does not apply to "),
            _createElementVNode("ol", null, [
              _createElementVNode("li", null, "i) breaches of these Terms resulting in death or personal injury."),
              _createElementVNode("li", null, "ii) intentional or grossly negligent breaches of these Terms by any party and their representatives, agents or contractors.")
            ])
          ]),
          _createElementVNode("li", null, "The Parties agree that for the purposes of this Clause 6, damage and losses include potential governmental fines.")
        ], -1)),
        _cache[23] || (_cache[23] = _createElementVNode("p", null, " ", -1)),
        _cache[24] || (_cache[24] = _createElementVNode("ol", { start: "7" }, [
          _createElementVNode("li", null, [
            _createElementVNode("strong", null, " Term and Termination")
          ]),
          _createElementVNode("li", null, "The Game will be available in a predetermined and agreed period of time"),
          _createElementVNode("li", null, [
            _createTextVNode(" MJSE may terminate your permission to offer the Game and the access to the Game Portal before the date set out in paragraph (a) "),
            _createElementVNode("ol", null, [
              _createElementVNode("li", null, "i) at any time by giving 1-week written notice (including by email), or"),
              _createElementVNode("li", null, "ii) by giving written notice if the other Party commits a breach of these Terms.")
            ])
          ]),
          _createElementVNode("li", null, "After termination, you may no longer use the print outs generated from the templates pursuant to Clause 1 (c) and must destroy them.")
        ], -1)),
        _cache[25] || (_cache[25] = _createElementVNode("p", null, " ", -1)),
        _cache[26] || (_cache[26] = _createElementVNode("ol", { start: "8" }, [
          _createElementVNode("li", null, [
            _createElementVNode("strong", null, " Miscellaneous")
          ]),
          _createElementVNode("li", null, " These Terms constitute the full and complete statement of the terms agreed between the Parties with respect to the subject matter thereof and supersede any previous or contemporaneous agreements, understandings, or communications, whether written or verbal, relating to its subject matter. "),
          _createElementVNode("li", null, " No variation of these Terms shall be effective unless it is in writing and signed by the authorised representatives of each of the Parties or via an online mechanism provided for such purpose by MJSE. "),
          _createElementVNode("li", null, "The law of the Federal Republic of Germany shall govern these Terms. Place of jurisdiction is Braunschweig, Germany."),
          _createElementVNode("li", null, "No provision of these Terms shall be enforceable by any person or individual other than the Parties."),
          _createElementVNode("li", null, " Should one or more provisions of these Terms be or become invalid, the validity of these Terms shall remain unaffected. In such a case, the Parties shall be obliged to replace the invalid provision with a valid provision that comes as close as possible to the economic purpose pursued by the invalid provision. The same applies to any gaps in these Terms. ")
        ], -1)),
        _cache[27] || (_cache[27] = _createElementVNode("p", null, " ", -1)),
        _cache[28] || (_cache[28] = _createElementVNode("p", null, " ", -1)),
        _cache[29] || (_cache[29] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, "Annex 1")
        ], -1)),
        _cache[30] || (_cache[30] = _createElementVNode("p", null, " ", -1)),
        _cache[31] || (_cache[31] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, "SAMPLE DATA PROTECTION NOTICE")
        ], -1)),
        _cache[32] || (_cache[32] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, "(International version for countries outside the EEA)")
        ], -1)),
        _cache[33] || (_cache[33] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, " ")
        ], -1)),
        _cache[34] || (_cache[34] = _createElementVNode("p", null, " We appreciate your interest in our company.  Data privacy is of particular importance to the Management Board of Mast-Jägermeister SE (hereinafter referred to as “MJSE”). This data protection notice contains information on the type, scope, and purpose of the personal data collected, used, and processed in connection with the game. Furthermore, data subjects are informed of their rights by means of this data protection notice. ", -1)),
        _cache[35] || (_cache[35] = _createElementVNode("p", null, " ", -1)),
        _cache[36] || (_cache[36] = _createElementVNode("ol", null, [
          _createElementVNode("li", null, [
            _createElementVNode("strong", null, " NAME AND ADDRESS OF THE DATA CONTROLLERS")
          ])
        ], -1)),
        _cache[37] || (_cache[37] = _createElementVNode("p", null, " The controllers, i.e. the entities deciding on the purposes and means of the processing of personal data, are MJSE and the Game organiser in question (i.e. the outlet where the Game is offered). ", -1)),
        _cache[38] || (_cache[38] = _createElementVNode("ul", null, [
          _createElementVNode("li", null, [
            _createElementVNode("strong", null, "Contact information of the organising outlet ")
          ])
        ], -1)),
        _cache[39] || (_cache[39] = _createElementVNode("p", null, "Contact information for the Game organiser can be obtained on its premises.", -1)),
        _cache[40] || (_cache[40] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, "1.2 Contact information of MJSE:")
        ], -1)),
        _cache[41] || (_cache[41] = _createElementVNode("p", null, "Mast-Jägermeister SE", -1)),
        _cache[42] || (_cache[42] = _createElementVNode("p", null, "Jägermeisterstraße 7–15", -1)),
        _cache[43] || (_cache[43] = _createElementVNode("p", null, "38296 Wolfenbüttel", -1)),
        _cache[44] || (_cache[44] = _createElementVNode("p", null, "Germany", -1)),
        _cache[45] || (_cache[45] = _createElementVNode("p", null, "Tel.: (0049) 05331810", -1)),
        _cache[46] || (_cache[46] = _createElementVNode("p", null, "E-mail: INFO@JAEGERMEISTER.DE", -1)),
        _cache[47] || (_cache[47] = _createElementVNode("p", null, "Website: WWW.JAGERMEISTER.COM", -1)),
        _cache[48] || (_cache[48] = _createElementVNode("p", null, " ", -1)),
        _cache[49] || (_cache[49] = _createElementVNode("ol", { start: "2" }, [
          _createElementVNode("li", null, [
            _createElementVNode("strong", null, " CONTACT DETAILS OF MJSE’S DATA PROTECTION OFFICER")
          ])
        ], -1)),
        _cache[50] || (_cache[50] = _createElementVNode("p", null, "MJSE’s data protection officer can be contacted at:", -1)),
        _cache[51] || (_cache[51] = _createElementVNode("p", null, "Data protection officer", -1)),
        _cache[52] || (_cache[52] = _createElementVNode("p", null, "Mast-Jägermeister SE", -1)),
        _cache[53] || (_cache[53] = _createElementVNode("p", null, "Jägermeisterstraße 7–15", -1)),
        _cache[54] || (_cache[54] = _createElementVNode("p", null, "38296 Wolfenbüttel", -1)),
        _cache[55] || (_cache[55] = _createElementVNode("p", null, "Germany", -1)),
        _cache[56] || (_cache[56] = _createElementVNode("p", null, "Tel.: +49 (0)5331 81266", -1)),
        _cache[57] || (_cache[57] = _createElementVNode("p", null, "E-mail: DATENSCHUTZ@JAEGERMEISTER.DE", -1)),
        _cache[58] || (_cache[58] = _createElementVNode("p", null, "Website: WWW.JAGERMEISTER.COM", -1)),
        _cache[59] || (_cache[59] = _createElementVNode("p", null, " ", -1)),
        _cache[60] || (_cache[60] = _createElementVNode("ol", { start: "3" }, [
          _createElementVNode("li", null, [
            _createElementVNode("strong", null, " COLLECTION OF GENERAL DATA AND INFORMATION ")
          ])
        ], -1)),
        _cache[61] || (_cache[61] = _createElementVNode("p", null, " The Game website operated by MJSE as the technical service provider for the respective Game organiser collects a range of general use data and information upon each visit by a data subject or an automated system. This general data and information is stored in the log files of the server of the Game website. The following data may be collected: (1) the browser types and versions used, (2) the operating system used by the accessing system, (3) the date and time when the Game website was accessed, (4) an internet protocol address (IP address), (6) the internet service provider of the accessing system, and (5) other similar data and information serving to avert risks in the event of attacks on our information technology systems. ", -1)),
        _cache[62] || (_cache[62] = _createElementVNode("p", null, " When using the general data and information, the controllers do not draw any conclusions about the data subject. Instead, this information is required to (1) correctly deliver the content of the Game website, (2) optimise the content of the Game website and the advertising for it, (3) ensure the long-term functionality of the Game website, and (4) to provide law enforcement authorities with the information necessary for prosecution in the event of a cyber-attack. Therefore, MJSE analyses this anonymously collected data and information on one hand for statistical purposes and on the other hand for the purpose of increasing the data protection and data security in connection with MJSE’s business operations, ultimately to ensure an optimal level of protection for the personal data that is processed. The anonymous data in the server log files is stored separately from any personal data provided by a data subject. ", -1)),
        _cache[63] || (_cache[63] = _createElementVNode("p", null, " The processing is carried out based on the legitimate interest of the controllers in improving the stability and functionality of the Game website and in order to enable the Game to be run. ", -1)),
        _cache[64] || (_cache[64] = _createElementVNode("p", null, "Recipients of the data:", -1)),
        _cache[65] || (_cache[65] = _createElementVNode("ol", null, [
          _createElementVNode("li", null, " The Game website is operated by the provider Brand Adventures a brand of Pocket Rocket GmbH, Essener Straße 4, 66606 St. Wendel, Germany, which has entered into an order processing agreement with MJSE. Brand Adventures processes the data collected through the Game website in Germany. ")
        ], -1)),
        _cache[66] || (_cache[66] = _createElementVNode("p", null, "Retention period: The collected data will be deleted after 14 months.", -1)),
        _cache[67] || (_cache[67] = _createElementVNode("p", null, " ", -1)),
        _cache[68] || (_cache[68] = _createElementVNode("ol", { start: "4" }, [
          _createElementVNode("li", null, [
            _createElementVNode("strong", null, " TECHNICALLY NECESSARY COOKIES")
          ])
        ], -1)),
        _cache[69] || (_cache[69] = _createElementVNode("p", null, " The Game website makes use of cookies. Cookies are text files that are stored in a computer system via a web browser. Many websites and servers use cookies. Many cookies contain a cookie ID, which acts as a unique identifier for the cookie. ", -1)),
        _cache[70] || (_cache[70] = _createElementVNode("p", null, " The use of cookies on the Game website is necessary as the content cannot be displayed correctly without it. The use of these technically necessary cookies is based on the legitimate interests of the controllers for the provision of the Game website. ", -1)),
        _cache[71] || (_cache[71] = _createElementVNode("p", null, " The data subject can prevent the setting of cookies by the Game website at any time by using the appropriate web browser settings and thus permanently refuse the setting of cookies. Furthermore, cookies that have already been set can be deleted at any time via a web browser or other software programmes. This is possible in all common web browsers. If the data subject deactivates the setting of cookies in the web browser used, it may not be possible to make full use of all the functions of the Game website. ", -1)),
        _cache[72] || (_cache[72] = _createElementVNode("p", null, "Recipients of the data: See under section 3.", -1)),
        _cache[73] || (_cache[73] = _createElementVNode("p", null, " Retention period: Most of the cookies used are session cookies. They are automatically deleted after the end of the visit to the Game website. Other cookies remain stored on the end device until the user deletes them. ", -1)),
        _cache[74] || (_cache[74] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, " ")
        ], -1)),
        _cache[75] || (_cache[75] = _createElementVNode("ol", { start: "5" }, [
          _createElementVNode("li", null, [
            _createElementVNode("strong", null, " COLLECTION OF SPECIFIC INFORMATION FOR THE RUNNING OF THE GAME")
          ])
        ], -1)),
        _cache[76] || (_cache[76] = _createElementVNode("p", null, "- Name of player", -1)),
        _cache[77] || (_cache[77] = _createElementVNode("p", null, "- Games in total and per player (all sessions and the number of times someone started a game)", -1)),
        _cache[78] || (_cache[78] = _createElementVNode("p", null, "- Game time", -1)),
        _cache[79] || (_cache[79] = _createElementVNode("p", null, " Date of birth: To participate in the Game, the visitor must enter their date of birth. The date of birth alone does not allow any conclusions to be drawn about the data subject. The date of birth is processed for the purpose of age verification to exclude Game participants who are not entitled to consume spirits on account of their age. ", -1)),
        _cache[80] || (_cache[80] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, "Smartphone"),
          _createTextVNode(" ID and cookies: In addition, the controllers collect the identification number of the smartphone used by the visitor and cookies to uniquely identify the smartphone. This is done to verify compliance with the Game’s terms and conditions of participation, according to which only one entry per person is permitted within 12 hours. ")
        ], -1)),
        _cache[81] || (_cache[81] = _createElementVNode("p", null, "- Node/Location => for this we need to track the IP address.", -1)),
        _cache[82] || (_cache[82] = _createElementVNode("p", null, "- date", -1)),
        _cache[83] || (_cache[83] = _createElementVNode("p", null, " - Cookies => we only save within the session e.g., the player names. Beyond the session, the session ID is stored as a \"cookie\" (local storage). This enables us to find out who comes back again. That is, who plays more often. ", -1)),
        _cache[84] || (_cache[84] = _createElementVNode("p", null, " The processing of the data in accordance with this section 5 is carried out in accordance with Art. 6(1)(b) GDPR for the purpose of running the Game in accordance with the terms and conditions of participation. ", -1)),
        _cache[85] || (_cache[85] = _createElementVNode("p", null, "Recipients of the data: See under Section 3.", -1)),
        _cache[86] || (_cache[86] = _createElementVNode("p", null, " Storage period: The data collected under this Section 5 will be deleted after 14 months. The cookies set remain stored on the end device until they are deleted by the user. ", -1)),
        _cache[87] || (_cache[87] = _createElementVNode("p", null, " ", -1)),
        _cache[88] || (_cache[88] = _createElementVNode("ol", { start: "6" }, [
          _createElementVNode("li", null, [
            _createElementVNode("strong", null, " RIGHTS OF THE DATA SUBJECT")
          ]),
          _createElementVNode("li", null, " a) Right to be informed: Every data subject has the right to receive information free of charge at any time from the controllers about the personal data stored concerning him or her, as well as a copy of this information. "),
          _createElementVNode("li", null, " b) Right to rectification: Every data subject has, the right to request the rectification without undue delay of any inaccurate personal data concerning him or her. "),
          _createElementVNode("li", null, " c) Right to erasure (“right to be forgotten”): Every data subject has the right to obtain from the controller the erasure of personal data concerning him or her without undue delay unless there are overriding reasons for the retention of the data. "),
          _createElementVNode("li", null, " d) Right to restriction of processing: Every data subject has the right to obtain a restriction of processing where one of the following conditions apply: ")
        ], -1)),
        _cache[89] || (_cache[89] = _createElementVNode("ul", null, [
          _createElementVNode("li", null, " the accuracy of the personal data is contested by the data subject, for a period enabling the controller to verify the accuracy of the personal data. "),
          _createElementVNode("li", null, "the processing is unlawful, and the data subject opposes the erasure of the personal data and requests the restriction of their use instead;"),
          _createElementVNode("li", null, " the controller no longer needs the personal data for the purposes of the processing, but they are required by the data subject for the establishment, exercise or defence of legal claims. "),
          _createElementVNode("li", null, " the data subject has objected to the processing pending the verification whether the legitimate grounds of the controller override those of the data subject. ")
        ], -1)),
        _cache[90] || (_cache[90] = _createElementVNode("p", null, " ", -1)),
        _cache[91] || (_cache[91] = _createElementVNode("ol", null, [
          _createElementVNode("li", null, [
            _createElementVNode("strong", null, "e) Right to object: every data subject has the right to object, on grounds relating to his or her situation, at any time to processing of personal data concerning him or her which is carried out on the basis of the legitimate interests of the controller. The data controller shall no longer process the personal data in the event of the objection, unless there are compelling legitimate grounds for the processing which override the interests, rights, and freedoms of the data subject, or the processing serves the purpose of establishing, exercising, or defending legal claims. ")
          ]),
          _createElementVNode("li", null, " g) Right to withdraw consent under data protection law: every data subject has the right to withdraw consent to the processing of personal data at any time. The withdrawal of consent shall not affect the lawfulness of the processing based on consent before its withdrawal. "),
          _createElementVNode("li", null, " h) Furthermore, you have the right to apply to a data protection authority with complaints concerning how your personal data is processed by us, for instance Die Landesbeauftragte für den Datenschutz Niedersachsen, Prinzenstraße 5, 30159 Hannover, Germany. ")
        ], -1)),
        _cache[92] || (_cache[92] = _createElementVNode("p", null, " If the data subject wishes to exercise their rights as a data subject under this section 7, they may contact the data protection officer or another employee of MJSE as well as the Game organiser at any time. ", -1)),
        _cache[93] || (_cache[93] = _createElementVNode("p", null, " ", -1)),
        _cache[94] || (_cache[94] = _createElementVNode("p", null, " ", -1)),
        _cache[95] || (_cache[95] = _createElementVNode("p", null, [
          _createElementVNode("u", null, [
            _createElementVNode("br")
          ])
        ], -1)),
        _cache[96] || (_cache[96] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, [
            _createElementVNode("u", null, "Annex 2")
          ])
        ], -1)),
        _cache[97] || (_cache[97] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, " ")
        ], -1)),
        _cache[98] || (_cache[98] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, "SAMPLE TERMS AND CONDITIONS OF PARTICIPATION")
        ], -1)),
        _cache[99] || (_cache[99] = _createElementVNode("p", null, " ", -1)),
        _cache[100] || (_cache[100] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, "SECTION 1 ORGANISER")
        ], -1)),
        _cache[101] || (_cache[101] = _createElementVNode("p", null, " (1) The organiser of the game is the particular outlet where you find the table display or other advertising for the Game. The organiser shall determine the timing of the Game and the type and number of prizes. For details, please contact the bar in question. But you can also use the game to fight over who will have to pay for the next round of delicious Ice Cold Jägermeister shots. ", -1)),
        _cache[102] || (_cache[102] = _createElementVNode("p", null, " (2) Mast-Jägermeister SE (hereinafter referred to as “MJSE”) shall run the Game as a technical service provider on behalf of the organiser. ", -1)),
        _cache[103] || (_cache[103] = _createElementVNode("p", null, " ", -1)),
        _cache[104] || (_cache[104] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, "SECTION 2 PARTICIPATION")
        ], -1)),
        _cache[105] || (_cache[105] = _createElementVNode("p", null, " (1) Adults with full legal capacity who are entitled to consume spirits under the law of the organiser’s place of business are eligible to participate. Every participant must provide accurate personal details. ", -1)),
        _cache[106] || (_cache[106] = _createElementVNode("p", null, "(2) Participation also requires an active internet connection. If participating via the mobile network, mobile data will be used.", -1)),
        _cache[107] || (_cache[107] = _createElementVNode("p", null, " ", -1)),
        _cache[108] || (_cache[108] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, "SECTION 3 RUNNING OF THE GAME")
        ], -1)),
        _cache[109] || (_cache[109] = _createElementVNode("p", null, " (1) Participants enter the Game via MJSE Game website via their smartphone browser by scanning a QR code printed on promotion material (e.g. table stands). ", -1)),
        _cache[110] || (_cache[110] = _createElementVNode("p", null, " (2) Only one entry per person is permitted within 12 hours. To comply with this restriction, the identifying details of the smartphone used to participate will be collected via the Game website. Details of this can be found in the data protection notice for the Game. ", -1)),
        _cache[111] || (_cache[111] = _createElementVNode("p", null, " ", -1)),
        _cache[112] || (_cache[112] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, "SECTION 4 PREMATURE DISCONTINUATION")
        ], -1)),
        _cache[113] || (_cache[113] = _createElementVNode("p", null, "The organiser may cancel or terminate the Game at any time if proper implementation is no longer guaranteed for legal or technical reasons.", -1)),
        _cache[114] || (_cache[114] = _createElementVNode("p", null, " ", -1)),
        _cache[115] || (_cache[115] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, "SECTION 5 COMPLIANCE WITH THE LAW/THIRD-PARTY RIGHTS")
        ], -1)),
        _cache[116] || (_cache[116] = _createElementVNode("p", null, "The participant is obliged to observe the applicable laws and all rights of third parties in connection with his/her participation.", -1)),
        _cache[117] || (_cache[117] = _createElementVNode("p", null, " ", -1)),
        _cache[118] || (_cache[118] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, "SECTION 6 EXCLUSION FROM PARTICIPATION")
        ], -1)),
        _cache[119] || (_cache[119] = _createElementVNode("p", null, " In the event of a breach of these terms and conditions of participation, the organiser reserves the right to exclude participants from the Game. Furthermore, the organiser reserves the right to exclude who use unauthorized aids or otherwise gain an advantage through manipulation. This is the case, for example, if automatic scripts, hacking tools, Trojans or viruses are used, or when a participant gains an advantage through other unauthorized means. Prizes may be withdrawn and/or reclaimed in such cases. ", -1)),
        _cache[120] || (_cache[120] = _createElementVNode("p", null, " ", -1)),
        _cache[121] || (_cache[121] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, "SECTION 7 LIABILITY")
        ], -1)),
        _cache[122] || (_cache[122] = _createElementVNode("p", null, " (1) The organiser and MJSE shall not be liable for any damage caused by errors, delays, or interruptions in the running of the Game, incorrect content, loss or deletion of data, viruses, or damage caused in any other way during participation in the Game, unless such damage has been caused by the organiser or MJSE (including its executive bodies, employees, or agents), intentionally or through gross negligence. The above limitation of liability shall not apply in the event of injury to life, limb, or health or in the event of fraudulently concealed defects. ", -1)),
        _cache[123] || (_cache[123] = _createElementVNode("p", null, "(2) Claims under Section 7 of the present Terms and Conditions must be asserted against the organiser.", -1)),
        _cache[124] || (_cache[124] = _createElementVNode("p", null, " ", -1)),
        _cache[125] || (_cache[125] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, "SECTION 8 APPLICABLE LAW")
        ], -1)),
        _cache[126] || (_cache[126] = _createElementVNode("p", null, " The Game is governed exclusively by the laws of the Federal Republic of Germany. This does not apply to regulations under the laws of the organiser’s country of domicile, insofar as these are more favorable to the participant than the corresponding regulations under the laws of the Federal Republic of Germany. ", -1)),
        _cache[127] || (_cache[127] = _createElementVNode("p", null, " ", -1)),
        _cache[128] || (_cache[128] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, "SECTION 9 DATA PROTECTION")
        ], -1)),
        _cache[129] || (_cache[129] = _createElementVNode("p", null, " Information on the processing of the participant’s personal data in connection with the provision of the Game website and the running of the Game can be found in the data protection notice for the Game. ", -1)),
        _cache[130] || (_cache[130] = _createElementVNode("p", null, " ", -1)),
        _cache[131] || (_cache[131] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, "SECTION 10 CONTACT PERSON AND RESPONSIBLE PARTY ")
        ], -1)),
        _cache[132] || (_cache[132] = _createElementVNode("p", null, " The organiser with responsibility for the Game is the participating outlet in question. All questions and complaints in connection with the Game are to be addressed directly to the outlet running the Game. This does not apply to questions and complaints relating to data processing via the Game website. The contact persons for the latter questions and complaints can be found in the data protection notice for the Game. ", -1)),
        _cache[133] || (_cache[133] = _createElementVNode("p", null, " ", -1)),
        _cache[134] || (_cache[134] = _createElementVNode("p", null, " ", -1)),
        _cache[135] || (_cache[135] = _createElementVNode("p", null, " ", -1)),
        _cache[136] || (_cache[136] = _createElementVNode("p", null, " ", -1))
      ])
    ]),
    _: 1
  }))
}
}

})