import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { useGlobalStore } from "../stores/global";

export default /*@__PURE__*/_defineComponent({
  __name: 'PlayButton',
  setup(__props) {

const globalStore = useGlobalStore();

const handleButtonClick = () => {
  globalStore.changeStep("gameMove");
  globalStore.playBackgroundMusic();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("button", {
      class: "text-white py-2 px-6 uppercase text-center w-full text-xl font-bold rounded-lg bg-orange-500",
      onClick: handleButtonClick
    }, _toDisplayString(_ctx.$t("age_check.confirm_button")), 1)
  ]))
}
}

})