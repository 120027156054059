import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container mx-auto h-full flex flex-col justify-center items-center" }

import { ref, onMounted } from "vue";
import backgroundImagePeople from "@/assets/images/backgrounds/bg-agegate-md.jpg";
import backgroundImageGreen from "@/assets/images/backgrounds/bg-green-xl.jpg";


export default /*@__PURE__*/_defineComponent({
  __name: 'DefaultLayout',
  props: {
    backgroundImage: {}
  },
  setup(__props: any) {

const background = ref<string>("");

const props = __props;

onMounted(() => {
  if(props.backgroundImage === "people") {
    background.value = backgroundImagePeople;
  } else {
    background.value = backgroundImageGreen;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "bg-cover bg-center absolute inset-0 overflow-hidden max-w-4xl mx-auto max-h-[1200px] pt-4 pb-4",
    style: _normalizeStyle({ backgroundImage: `url(${background.value})` })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 4))
}
}

})