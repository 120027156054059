<template>
  <div
    class="bg-cover bg-center absolute inset-0 overflow-hidden max-w-4xl mx-auto max-h-[1200px] pt-4 pb-4"
    :style="{ backgroundImage: `url(${background})` }"
  >
    <div class="container mx-auto h-full flex flex-col justify-center items-center">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, defineProps, onMounted } from "vue";
import backgroundImagePeople from "@/assets/images/backgrounds/bg-agegate-md.jpg";
import backgroundImageGreen from "@/assets/images/backgrounds/bg-green-xl.jpg";

const background = ref<string>("");

const props = defineProps<{
  backgroundImage: string;
}>();

onMounted(() => {
  if(props.backgroundImage === "people") {
    background.value = backgroundImagePeople;
  } else {
    background.value = backgroundImageGreen;
  }
});
</script>

<style lang="scss" scoped>
@import "@/assets/sass/main.sass";
</style>
