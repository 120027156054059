import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container py-4 font-bold uppercase" }
const _hoisted_2 = { class: "age-check-input" }
const _hoisted_3 = { class: "input-wrap" }
const _hoisted_4 = { class: "label-wrap white" }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "input-wrap" }
const _hoisted_8 = { class: "label-wrap" }
const _hoisted_9 = { class: "label" }
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = { class: "input-wrap" }
const _hoisted_12 = { class: "label-wrap" }
const _hoisted_13 = { class: "label" }
const _hoisted_14 = ["placeholder"]
const _hoisted_15 = { class: "is-text-orange px-3" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = {
  key: 1,
  class: "is-text-orange"
}
const _hoisted_18 = {
  key: 2,
  class: "is-text-orange"
}

import { ref, watch, nextTick } from "vue";
import moment from "moment";
import { useGlobalStore } from "../stores/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'AgeSelector',
  setup(__props) {

const globalStore = useGlobalStore();

const day = ref("");
const month = ref("");
const year = ref("");
const errorDay = ref(false);
const errorMonth = ref(false);
const errorYear = ref(false);

const dayInputRef = ref<HTMLInputElement | null>(null);
const monthInputRef = ref<HTMLInputElement | null>(null);
const yearInputRef = ref<HTMLInputElement | null>(null);

const removeScroll = () => {
  document.addEventListener(
    "touchmove",
    function (e) {
      if (e.cancelable) {
        e.preventDefault();
      }
    },
    { passive: false }
  );
  console.log("scroll removed");
};

const validateBirthday = () => {
  console.log("validating");
  if (errorDay.value === false && errorMonth.value === false && errorYear.value === false) {
    const birthday = moment(`${day.value}.${month.value}.${year.value}`, "DD.MM.YYYY");
    const age = moment.duration(moment().diff(birthday)).asYears();

    if (age >= 18) {
      globalStore.changeStep("peopleCheck");
      removeScroll();
    } else {
      globalStore.changeStep("ageCheckFailed");
    }
  } else {
    console.log("not validating");
  }
};

const correctDayInput = () => {
  if (day.value.length === 1 && day.value !== "0") {
    day.value = `0${day.value}`;
  }
};

const correctMonthInput = () => {
  if (month.value.length === 1 && month.value !== "0") {
    month.value = `0${month.value}`;
  }
};

watch(day, async (newDay) => {
  day.value = newDay.replace(/\D/g, "");
  if (day.value.length === 2 && parseInt(day.value) > 0 && parseInt(day.value) <= 31) {
    errorDay.value = false;
    monthInputRef.value?.focus();
  } else if (day.value.length > 1) {
    errorDay.value = true;
  }
});

watch(month, async (newMonth) => {
  month.value = newMonth.replace(/\D/g, "");
  if (String(newMonth).length === 2 && parseInt(newMonth) > 0 && parseInt(newMonth) <= 12) {
    errorMonth.value = false;
    await nextTick();
    yearInputRef.value?.focus();
  } else if (newMonth.length > 1 || parseInt(newMonth) <= 0 || parseInt(newMonth) > 12) {
    errorMonth.value = true;
  }
});

watch(year, (newYear) => {
  const currentYear = new Date().getFullYear();
  if (String(newYear).length === 4 && parseInt(newYear) > 999 && parseInt(newYear) <= currentYear) {
    errorYear.value = false;
    validateBirthday();
  } else if (newYear.length > 3 || parseInt(newYear) <= 999 || parseInt(newYear) > currentYear) {
    errorYear.value = true;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("age_check.day")), 1)
          ]),
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["white", ['input', { validated: errorDay.value === false }]]),
            type: "text",
            inputmode: "numeric",
            pattern: "[0-9]*",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((day).value = $event)),
            placeholder: _ctx.$t('age_check.day_placeholder'),
            ref_key: "dayInputRef",
            ref: dayInputRef
          }, null, 10, _hoisted_6), [
            [_vModelText, day.value]
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("age_check.month")), 1)
          ]),
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["white", ['input', { validated: errorMonth.value === false }]]),
            onFocus: _cache[1] || (_cache[1] = ($event: any) => (correctDayInput())),
            type: "text",
            inputmode: "numeric",
            pattern: "[0-9]*",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((month).value = $event)),
            placeholder: _ctx.$t('age_check.month_placeholder'),
            ref_key: "monthInputRef",
            ref: monthInputRef
          }, null, 42, _hoisted_10), [
            [_vModelText, month.value]
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("age_check.year")), 1)
          ]),
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["white", ['input large', { validated: errorYear.value === false }]]),
            onFocus: _cache[3] || (_cache[3] = ($event: any) => (correctMonthInput())),
            type: "number",
            inputmode: "numeric",
            pattern: "[0-9]*",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((year).value = $event)),
            placeholder: _ctx.$t('age_check.year_placeholder'),
            ref_key: "yearInputRef",
            ref: yearInputRef
          }, null, 42, _hoisted_14), [
            [_vModelText, year.value]
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          (errorDay.value)
            ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(_ctx.$t("age_check.error_day_message")), 1))
            : _createCommentVNode("", true),
          (errorMonth.value)
            ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(_ctx.$t("age_check.error_month_message")), 1))
            : _createCommentVNode("", true),
          (errorYear.value)
            ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(_ctx.$t("age_check.error_year_message")), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}
}

})