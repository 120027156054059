import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center h-full" }
const _hoisted_2 = { class: "flex-1 flex flex-col justify-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "text-lg leading-tight max-w-md text-jm_white px-3 [text-shadow:_0_0px_10px_rgba(0_0_0_/_40%)]" }
const _hoisted_5 = { class: "text-lg font-bold uppercase leading-tight max-w-md text-jm_white px-3 [text-shadow:_0_0px_10px_rgba(0_0_0_/_40%)]" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "relative flex flex-col justify-center items-center" }
const _hoisted_8 = ["src"]

import DefaultLayout from "../layouts/DefaultLayout.vue";
import AgeSelector from "@/components/AgeSelector.vue";
import logo from "@/assets/images/misc/logo.png";
import drinkReponsible from "@/assets/images/misc/drink-responsible.svg";
import { useGlobalStore } from "../stores/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'ageCheck',
  setup(__props) {

const globalStore = useGlobalStore();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DefaultLayout, { "background-image": "green" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _unref(logo),
            alt: "logo",
            class: "w-[150px] mb-6 slide mx-auto"
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("age_check.message")), 1),
          _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("age_check.enter_birthday")), 1),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(AgeSelector)
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("div", null, null, -1))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", null, [
            _createElementVNode("img", {
              src: _unref(drinkReponsible),
              alt: "drink responsible",
              class: "w-[160px] mb-6"
            }, null, 8, _hoisted_8)
          ]),
          _createElementVNode("div", {
            class: "text-sm uppercase cursor-pointer opacity-50 text-jm_white mb-4 text-center",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(globalStore).changeStep('termsAndConditions')))
          }, _toDisplayString(_ctx.$t("age_check.legal_conditions")), 1)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})