import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-regular bg-jm_green" }

import { useGlobalStore } from "./stores/global";
import AgeCheck from "./pages/ageCheck.vue";
import PeopleCheck from "./pages/peopleCheck.vue";
import { onMounted, onUnmounted } from "vue";
import AgeCheckFailed from "./pages/ageCheckFailed.vue";
import GameMove from "./pages/gameMove.vue";
import TermsAndConditions from "./pages/termsAndConditions.vue";
import i18n from "@/plugins/i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const globalStore = useGlobalStore();

onMounted(() => {
  let lang = new URL(location.href).searchParams.get("lang");

  if (lang) {
    i18n.global.locale = lang;
  }

  globalStore.changeStep("ageCheck");

  document.addEventListener('visibilitychange', toggleMusic);

});

onUnmounted(() => {
  document.removeEventListener('visibilitychange', toggleMusic);
});

const toggleMusic = () => {
  if (document.hidden) {
    globalStore.pauseBackgroundMusic();
  } else {
    globalStore.playBackgroundMusic();
  }
};



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(globalStore).currentStep === 'ageCheck')
      ? (_openBlock(), _createBlock(AgeCheck, { key: 0 }))
      : (_unref(globalStore).currentStep === 'peopleCheck')
        ? (_openBlock(), _createBlock(PeopleCheck, { key: 1 }))
        : (_unref(globalStore).currentStep === 'ageCheckFailed')
          ? (_openBlock(), _createBlock(AgeCheckFailed, { key: 2 }))
          : (_unref(globalStore).currentStep === 'gameMove')
            ? (_openBlock(), _createBlock(GameMove, { key: 3 }))
            : (_unref(globalStore).currentStep === 'termsAndConditions')
              ? (_openBlock(), _createBlock(TermsAndConditions, { key: 4 }))
              : _createCommentVNode("", true)
  ]))
}
}

})