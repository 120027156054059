<template>
  <div class="font-regular bg-jm_green">
    <AgeCheck v-if="globalStore.currentStep === 'ageCheck'" />
    <PeopleCheck v-else-if="globalStore.currentStep === 'peopleCheck'" />
    <AgeCheckFailed v-else-if="globalStore.currentStep === 'ageCheckFailed'" />
    <GameMove v-else-if="globalStore.currentStep === 'gameMove'" />
    <TermsAndConditions v-else-if="globalStore.currentStep === 'termsAndConditions'" />
  </div>
</template>

<script lang="ts" setup>
import { useGlobalStore } from "./stores/global";
import AgeCheck from "./pages/ageCheck.vue";
import PeopleCheck from "./pages/peopleCheck.vue";
import { onMounted, onUnmounted } from "vue";
import AgeCheckFailed from "./pages/ageCheckFailed.vue";
import GameMove from "./pages/gameMove.vue";
import TermsAndConditions from "./pages/termsAndConditions.vue";
import i18n from "@/plugins/i18n";

const globalStore = useGlobalStore();

onMounted(() => {
  let lang = new URL(location.href).searchParams.get("lang");

  if (lang) {
    i18n.global.locale = lang;
  }

  globalStore.changeStep("ageCheck");

  document.addEventListener('visibilitychange', toggleMusic);

});

onUnmounted(() => {
  document.removeEventListener('visibilitychange', toggleMusic);
});

const toggleMusic = () => {
  if (document.hidden) {
    globalStore.pauseBackgroundMusic();
  } else {
    globalStore.playBackgroundMusic();
  }
};


</script>

<style lang="scss" scoped>
@import "@/assets/sass/main.sass";
</style>
