import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center h-full" }
const _hoisted_2 = { class: "flex-1 flex flex-col items-center justify-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "relative flex flex-col justify-center items-center" }
const _hoisted_6 = ["src"]

import PlayButton from "@/components/PlayButton.vue";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import PeopleSelector from "@/components/PeopleSelector.vue";
import logo from "@/assets/images/misc/logo.svg";
import shotMachine from "@/assets/images/misc/shot_machine.svg";
import drinkReponsible from "@/assets/images/misc/drink-responsible.svg";
import { useGlobalStore } from "../stores/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'peopleCheck',
  setup(__props) {

const globalStore = useGlobalStore();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DefaultLayout, { "background-image": "people" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _unref(logo),
            alt: "logo",
            class: "w-[150px] mb-3 slide mx-auto"
          }, null, 8, _hoisted_3),
          _createElementVNode("img", {
            src: _unref(shotMachine),
            alt: "logo",
            class: "w-[250px] mb-3 mt-6 slide mx-auto"
          }, null, 8, _hoisted_4),
          _createElementVNode("div", null, [
            _createVNode(PeopleSelector, { class: "text-center mb-3" })
          ]),
          _createVNode(PlayButton, { class: "mb-6 slide" }),
          _cache[1] || (_cache[1] = _createElementVNode("div", null, null, -1))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", null, [
            _createElementVNode("img", {
              src: _unref(drinkReponsible),
              alt: "drink responsible",
              class: "w-[160px] mb-6"
            }, null, 8, _hoisted_6)
          ]),
          _createElementVNode("div", {
            class: "text-sm uppercase cursor-pointer opacity-50 text-jm_white mb-4 text-center",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(globalStore).changeStep('termsAndConditions')))
          }, _toDisplayString(_ctx.$t("age_check.legal_conditions")), 1)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})