<template>
  <div class="container py-4 font-bold uppercase">
    <div>
      <div class="age-check-input">
        <div class="input-wrap">
          <div class="label-wrap white">
            <div class="label">{{ $t("age_check.day") }}</div>
          </div>
          <input
            class="white"
            type="text"
            inputmode="numeric"
            pattern="[0-9]*"
            :class="['input', { validated: errorDay === false }]"
            v-model="day"
            :placeholder="$t('age_check.day_placeholder')"
            ref="dayInputRef"
          />
        </div>
        <div class="input-wrap">
          <div class="label-wrap">
            <div class="label">{{ $t("age_check.month") }}</div>
          </div>
          <input
            class="white"
            @focus="correctDayInput()"
            type="text"
            inputmode="numeric"
            pattern="[0-9]*"
            :class="['input', { validated: errorMonth === false }]"
            v-model="month"
            :placeholder="$t('age_check.month_placeholder')"
            ref="monthInputRef"
          />
        </div>
        <div class="input-wrap">
          <div class="label-wrap">
            <div class="label">{{ $t("age_check.year") }}</div>
          </div>
          <input
            class="white"
            @focus="correctMonthInput()"
            type="number"
            inputmode="numeric"
            pattern="[0-9]*"
            :class="['input large', { validated: errorYear === false }]"
            v-model="year"
            :placeholder="$t('age_check.year_placeholder')"
            ref="yearInputRef"
          />
        </div>
        <div class="is-text-orange px-3">
          <p v-if="errorDay">
            {{ $t("age_check.error_day_message") }}
          </p>
          <p
            v-if="errorMonth"
            class="is-text-orange"
          >
            {{ $t("age_check.error_month_message") }}
          </p>
          <p
            v-if="errorYear"
            class="is-text-orange"
          >
            {{ $t("age_check.error_year_message") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, nextTick } from "vue";
import moment from "moment";
import { useGlobalStore } from "../stores/global";

const globalStore = useGlobalStore();

const day = ref("");
const month = ref("");
const year = ref("");
const errorDay = ref(false);
const errorMonth = ref(false);
const errorYear = ref(false);

const dayInputRef = ref<HTMLInputElement | null>(null);
const monthInputRef = ref<HTMLInputElement | null>(null);
const yearInputRef = ref<HTMLInputElement | null>(null);

const removeScroll = () => {
  document.addEventListener(
    "touchmove",
    function (e) {
      if (e.cancelable) {
        e.preventDefault();
      }
    },
    { passive: false }
  );
  console.log("scroll removed");
};

const validateBirthday = () => {
  console.log("validating");
  if (errorDay.value === false && errorMonth.value === false && errorYear.value === false) {
    const birthday = moment(`${day.value}.${month.value}.${year.value}`, "DD.MM.YYYY");
    const age = moment.duration(moment().diff(birthday)).asYears();

    if (age >= 18) {
      globalStore.changeStep("peopleCheck");
      removeScroll();
    } else {
      globalStore.changeStep("ageCheckFailed");
    }
  } else {
    console.log("not validating");
  }
};

const correctDayInput = () => {
  if (day.value.length === 1 && day.value !== "0") {
    day.value = `0${day.value}`;
  }
};

const correctMonthInput = () => {
  if (month.value.length === 1 && month.value !== "0") {
    month.value = `0${month.value}`;
  }
};

watch(day, async (newDay) => {
  day.value = newDay.replace(/\D/g, "");
  if (day.value.length === 2 && parseInt(day.value) > 0 && parseInt(day.value) <= 31) {
    errorDay.value = false;
    monthInputRef.value?.focus();
  } else if (day.value.length > 1) {
    errorDay.value = true;
  }
});

watch(month, async (newMonth) => {
  month.value = newMonth.replace(/\D/g, "");
  if (String(newMonth).length === 2 && parseInt(newMonth) > 0 && parseInt(newMonth) <= 12) {
    errorMonth.value = false;
    await nextTick();
    yearInputRef.value?.focus();
  } else if (newMonth.length > 1 || parseInt(newMonth) <= 0 || parseInt(newMonth) > 12) {
    errorMonth.value = true;
  }
});

watch(year, (newYear) => {
  const currentYear = new Date().getFullYear();
  if (String(newYear).length === 4 && parseInt(newYear) > 999 && parseInt(newYear) <= currentYear) {
    errorYear.value = false;
    validateBirthday();
  } else if (newYear.length > 3 || parseInt(newYear) <= 999 || parseInt(newYear) > currentYear) {
    errorYear.value = true;
  }
});
</script>

<style lang="sass" scoped>
@import "@/assets/sass/main.sass"
.input-wrap
  position: relative
  display: flex

  .label-wrap
    flex: 0 2rem

    .label
      color: $jm_white
      text-transform: uppercase
      position: absolute
      font-size: .7rem
      transform: rotate(270deg) translateX(-100%)
      top: 0
      left: 0
      transform-origin: top left
      padding: 0.5rem 0.8rem 0.5rem 0
      letter-spacing: 0.2rem

  input
    width: 12rem
    height: 5rem
    margin-bottom: 2rem
    opacity: .75
    color: $jm_white
    font-family: 'Meister-Bold', Helvetica, Arial, sans-serif

    &:focus
      opacity: 1

    &.large
      width: 15rem

.age-check
  background-size: cover

p
  font-size: 1.8rem

h1
  text-align: center

.box
  background-repeat: no-repeat
  background-position: right center
  background-attachment: fixed
  background-size: cover

  span
    margin: 8px

.is-text-orange
  color: #ff9800
  font-size: .8rem
@media (max-height: 740px)
  .input-wrap
    input
      height: 3rem 
      font-size: 2.8rem
      margin-bottom: 2.5rem

    .label-wrap
      .label
        font-size: .5rem
        padding: 0.5rem .1rem .5rem 0
        letter-spacing: 0.2rem

  p
    font-size: 1.5rem
</style>